.notes-continer {
    padding-top: 16px;
    padding-left: 10px;
    background: #fff;
    .select-all-button {
        margin: 10px 0;
        padding: 8px 16px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .select-all-container{
        display: flex;
        justify-content: flex-end;
        margin-right: 33px;
    }
    
    .select-all-button:hover {
        background-color: #0056b3;
    }
    .row {
        margin: 0 !important;
        padding: 0 !important;
        @media (max-width: 590px) {
            flex-direction: column-reverse;
        }
        .notes-list {
            // height: 75vh;
            overflow-y: auto;
            padding: 0px !important;
            padding: 0 12px !important;
            .row {
                margin: 0 !important;
                padding: 0 !important;
                max-height: 75vh;
                .notes-card {
                    padding: 6px !important;
                    .inner-content {
                        position: relative;
                        .name-layer {
                            width: 100%;
                            height: 100%;
                            background: #000;
                            position: absolute;
                            opacity: 0.8;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #fff;
                            padding: 16px;
                            cursor: pointer;
                            &:hover {
                                opacity: 0.5;
                            }
                        }
                        .active-img {
                            opacity: 0 !important;
                        }
                        img {
                            width: 100%;
                        }
                    }
                }
                .select-button-container {
                    text-align: center;
                }
                
                .select-button {
                    width: 90%;
                    padding: 8px 16px;
                    border: none;
                    background-color: #0178e4;
                    color: white;
                    cursor: pointer;
                    margin-bottom: 20px;
                    border-radius: 4px;
                    transition: background-color 0.3s ease;
                }
                
                .select-button.selected {
                    background-color: #012675;
                    cursor: default;
                }
                
                .select-button:disabled {
                    background-color: #ccc;
                    cursor: not-allowed;
                }
                
                .save-button-container {
                    text-align: center;
                    margin-top: 20px;
                }
                
                .save-button {
                    padding: 10px 20px;
                    background-color: #007bff;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }
                
                .save-button:disabled {
                    background-color: #ccc;
                    cursor: not-allowed;
                }
                .check-icon {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: #28a745;
                    color: #fff;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                }
                
               
                
            }
        }
        .select-note {
            height: 75vh;
            overflow-y: scroll;
            .selected-section {
                padding: 16px 10px;
                .list-heading {
                    font-size: 20px;
                    font-weight: bolder;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    padding-bottom: 16px;
                    .edit-action {
                        font-size: 18px;
                        color: var(--bs-gray-600);
                        padding: 0 16px;
                        cursor: pointer;
                        i{padding: 0 2px;}
                        &:hover {
                            color: var(--color-green-3);
                            i {
                                font-weight: bold;
                            }
                        }
                    }
                }
                .img-continer{
                    padding-bottom: 8px;
                    text-align: center;
                .slected-img {
                    width: 78%;
                }
            }
            }
        }
    }
    .save-button-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 58px;
        margin-top: 30px;
        padding-bottom: 20px;
        .save-button {
            padding: 10px 20px;
            background-color: #051450;
            color: white;
            width: 200px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }
        .save-button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }
}
