.vignettes-continer{
    padding:16px;
    background:#fff;
    .select-all-container {
        margin-bottom: 15px;
        text-align: right;
        margin-right: 28px;
        .select-all-button {
            padding: 8px 12px;
            border: none;
            cursor: pointer;
            background-color: #007bff;
            color: white;
            border-radius: 4px;

            &.selected {
                background-color: #28a745;
            }
        }
    }

    .inner-content {
        position: relative;
        cursor: pointer;

        &.selected {
            border: 2px solid #28a745;
        }
img{
    width: 100%;
}
   
        
    }
    .thamp-list{
        overflow-y: scroll;
        // height: 80vh;
        padding: 12px;
        .thamp-list-item{
            max-height: 250px;
            margin-bottom: 35px ;
            position: relative;
            .select-button-container {
                text-align: center;
            }
            
            .select-button {
                width: 100%;
                padding: 8px 16px;
                border: none;
                background-color: #0178e4;
                color: white;
                cursor: pointer;
                border-radius: 4px;
                transition: background-color 0.3s ease;
            }
            
            .select-button.selected {
                background-color: #012675;
                cursor: default;
            }
            
            .select-button:disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }
            
            .save-button-container {
                text-align: center;
                margin-top: 20px;
            }
            
            .save-button {
                padding: 10px 20px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s ease;
            }
            
            .save-button:disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }

            .a-tag{text-decoration: none !important;}
            .layer{
                position: absolute;
                z-index: 2;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                i{
                    position: absolute;
                    margin-right: 24px;
                    font-size: 30px;
                    background-color: #fff;
                    cursor: pointer;
                    z-index: 99;
                    &:hover{
                        font-size: 35px;
                    }
                }
            }
            .config-layer{
                position: absolute;
                z-index: 2;
                width: 92%;
                display: flex;
                justify-content: flex-end;
                i{
                    position: absolute;
                    margin-right: 24px;
                    font-size: 30px;
                    background-color: #fff;
                    cursor: pointer;
                    z-index: 99;
                    &:hover{
                        font-size: 35px;
                    }
                }
            }
                       
            a{
                cursor: pointer;
                z-index: 9;
                .inner-content{
                    position: relative;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    img{width: 100%;
                        height: 100%;}
                    .content{
                        position: relative;
                        bottom: 58px;
                        font-size: 12px;
                        z-index: 9;
                        padding: 15px;
                        background: rgba(0, 0, 0, 0.7);
                        width: 100%;
                        color: white;
                    }
                }
            }
        }
    }
    .save-button-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 30px;
        .save-button {
            padding: 10px 20px;
            background-color: #051450;
            color: white;
            width: 200px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }
        .save-button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }
}
.no-data{
    text-align: center;
    font-size: 25px;
    font-weight: bolder;
}

.vignettes-continer {
    .inner-content {
        position: relative;
        cursor: pointer;

        &.selected {
            border: 2px solid #28a745;
        }

        .icons-container {
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            gap: 8px;

            .check-icon {
                background-color: white;
                border: 2px solid #28a745;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #28a745;
            }

            .play-icon {
                background-color: white;
                border: 2px solid #007bff;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #007bff;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background-color: #007bff;
                    color: white;
                }
            }
        }
    }
}
