.courselist-continer{
    // padding: 16px;
    .color-ce-green{
        color:#0178E6;
        width: 35px;
        height: 35px;
    }
    .copy-tag{
        padding-left: 12px;
        display: none;
        text-align: center;
        cursor: pointer;
    }
    .courseList:hover .copy-tag {
        display: block;
    }
    .MuiAccordionSummary-content {
        p.MuiTypography-root {
            font-size: var(--list-font-size);
            font-weight: 600;
            font-family: "Work Sans" !important;
            white-space:  nowrap;
            @media (max-width:'280px') {
            white-space: inherit;
            }
        }
    }
    .css-10hburv-MuiTypography-root{
        font-family: "Work Sans" !important;
        padding: 4px;
    }
    .MuiAccordion-rounded {
        border-radius: 0
    }
    .MuiAccordion-root {
        box-shadow: var(--box-shadow-2);
    }
    .css-15v22id-MuiAccordionDetails-root{
        padding: 0!important;
    }
    .MuiList-padding{
        background-color:var(--color-gray-8) !important;
        padding: 10px 10px 0px 10px !important;
    }
  .MuiListItemSecondaryAction-root{
        position: absolute;
        // right: -10px!important;
        top: 50%;
        transform: translateY(-50%);
    }
    .MuiCollapse-root{
        .MuiCollapse-wrapper{
        border: none !important;
        box-shadow: 0 2px 16px 4px RGB(40 44 63 / 5%);
        min-height:0px !important;
        .course-courseList-continer{
            padding: 0 15px;
            margin: 0;
            list-style-type: none;
            background-color: var(--color-gray-8)!important;
            .courseList{
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                padding: 10px;
                border-bottom: 1px solid #ddd;
                align-items: center;
                column-gap: 8px;
                @media (max-width:'280px') {
                    flex-wrap: wrap !important;
                    }
                .course-courseList-coursename{
                    display: flex;
                    width: 600px;
                    font-size: var(--list-font-size);
                    align-items: center;
                        button{
                            svg{
                                width: 15px;
                            }
                        }
                    @media (max-width: '992px'){
                                width: 300px !important;
                                font-size: 12px;
                                align-items: center;
                         }
                }
                .migrated-tag{
                    width: 3%;
                    margin-right: 5px;
                    padding-bottom: 1px;
                    img{
                        width: 80%;
                    }
                }
                .course-courseList-action{
                    cursor: pointer;
                }
                .course-courseList-btn{
                     min-width: 330px;
                     display: flex;
                     justify-content: flex-start;
                     flex-wrap: wrap;
                     align-items: center;
                     flex-shrink: 0;
                     @media (max-width: '768px'){
                        height:70px;
                        min-width:auto;
                       }
                       @mixin btnsizing {
                        @media (max-width: '768px'){
                            font-size: 8px;
                            }
                      }
                     .btn-preview{
                                 background-color:var(--color-green-3);
                                 color:#fff;
                                 border: 1px solid var(--color-green-3);
                                 white-space:nowrap;
                                 min-width: 80px;
                                 @include btnsizing();
                                &:hover{
                                border: 1px solid var(--color-green-3);
                                 background-color:transparent;
                                 color:var(--color-green-3);
                               }
                             }
                    .btn-author{
                                white-space:nowrap;
                                min-width: 80px;
                                @include btnsizing();
                                &:hover{
                                    background-color:var(--color-green-3);
                                    color:var(--color-white);
                            }
                            .scorm-loader{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .spinner-border{
                                    margin-left: 15px;
                                    width: 1.5rem;
                                    height: 1.5rem;
                                }
                            }
                        }
                }
            }
            .options-area{
                right: 46px !important;
                margin-top: -26px !important;
                text-align: left;
                z-index: 1;
            }
        }
        }
    }
    .MuiAccordionDetails-root {
        .MuiTypography-body1 {
            font-size: 1rem;
            font-family: unset;
        }
        .course-courseList-continer{
            width: 100%;
        }
    }
}
.panell-continer{ padding: 0 !important; }
.btn{
    border:1px solid #ddd;
    border-radius: 5px;
    text-transform: uppercase;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;;
}
.active{
    background-color:#757575;
    border-radius: 2px;
    color: var(--color-white);
}

.btn-localization{
    &:hover{
        border: 1px solid #0178e6;
        color: #0178e6;
        
    }
    border: 1px solid #000;
    margin-top: 20px;
    text-align: center;
    // width: 100%;
    display: block                                                                                              ;
}
.modal-close-icon{
    top:10px;
    right:20px
}
.btn-localization.submit-btn{
    background-color: var(--color-green-3);
    color: var(--color-white);
    border: 1px solid #0178e6;
    &:hover {
        background-color: #fff;
        color: #000;
        border: 1px solid black;
    }
}
.stack{
    flex-wrap: wrap !important;
    gap : 8px !important;
    justify-content: flex-start;
    .chip{
        margin-left: 0px !important;
    }
}
#circle {
  border-radius: 50%;
    border: 2px solid #0178e6;
    width: 14px;
    height: 14px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: absolute;
    margin-right: 5px;
  }
  
  #inner-letter {
    color:#0178e6;
    font-size: 8px; /* Adjust size of the inner letter */
    font-weight:bolder;
    bottom: 5px; /* Adjust vertical position */
    transform: translateY(0px) !important; /* Center vertically */
    transform: translateX(25%);
  }
  .comment-modal-text{
    border-radius: 3px;
    &:focus{
        border-color: #0178E6;
        outline: 1px solid #0178E6 !important;
    }
  }
  

