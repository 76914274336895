.acl-items{
    background-color: #fff;
    box-shadow: var(--box-shadow-3);
    padding:20px;
    margin-top: 30px;
    @media (max-Width:1200px) {
        min-width: 1170px;   
    }
    .features{
        margin-top: 30px;
        .acl-flex-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ddd;
        }
    }
    .role-items{
        display: flex;
        justify-content:space-between;
        align-items: center;
        margin-bottom: 0;
        li{
            font-weight: 600;
        }
    }
    
    .list-style-none{
        list-style-type: none;
        padding-left: 0;
    }
    
    .custom-position{
        flex:1 1 auto;
    }
    .bold-text{
        font-weight: 600;
    }
    .action-type{
        position: sticky;
        left: 0;
        z-index: 2;
        background: var(--color-white);
        width: 15%;
        padding-left: 5px;
    }

    .btn{
        min-width: 100px;
    }
    .btn-disabled{
        background-color: grey
    }
    .btn-save{
        background-color: var(--color-green-3);
        
        color:#fff;
    }

}
.acl-items-container{
    @media (max-Width:1200px) {
       overflow:scroll; 
    }
}