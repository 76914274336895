.courses-list{
    list-style-type: none;
    padding-left: 0;
    box-shadow: var(--box-shadow-3);
    li{
        padding:var(--list-item-padding);
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        font-size: var(--list-font-size);
    }
}