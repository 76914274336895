.course-assign-container {
    .title-container {
        padding-bottom: 11px;
    }

    .css-abna4k-MuiList-root {
        width: 285px;
    }

    .css-1m4ljmh-MuiTypography-root {
        font-weight: 500;
        font-size: 17px;
        font-family: 'Work Sans', sans-serif !important;
    }

    .css-15v22id-MuiAccordionDetails-root {

        padding: 0px;

    }

    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {

        padding-right: 0px;

    }

    .css-et1ao3-MuiTypography-root {
        font-size: 14px;
        font-family: 'Work Sans', sans-serif !important
    }

    .css-355xbt-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
    .css-355xbt-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
        color: #212529;
    }

    .css-qyxyi3-MuiButtonBase-root-MuiListItem-root {
        padding-top: 0px;
        padding-bottom: 0px;

    }

    .css-ak74bx-MuiButtonBase-root-MuiButton-root {
        padding: 7px 12px;
        color: black;
        border: 1px solid black;
    }
    .MuiGrid-root.MuiGrid-container{
        flex-wrap: nowrap !important;
        .MuiGrid-item{
            width: 40% !important;
        }
    }
}

.assigned-courses {
    display: flex;
    justify-content: right;
    padding-top: 17px;

    .btn-disabled {
        border: none;
        padding: 5px;
        border-radius: 3px;
        margin-left: 10px;
    }

    .add-btn {
        border: none;
        background-color: var(--color-green-3);
        color: #fff;
        padding: 5px;
        border-radius: 3px;
        margin-left: 10px;
    }
}