.modal-header-approve {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  
  .modal-actions > button {
    margin-left: 8px;
  }
  .MuiFormLabel-asterisk.MuiInputLabel-asterisk{
    color:#ff0a3c ;
  }