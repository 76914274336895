.card-container {
  padding: 20px;
  width: 350px;
  //   height: 209px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #efefef;
  .card-profile {
    display: flex;
    gap: 10px;
    align-items: center;

    .image {
      line-height: 0;
      i {
        // width: 37px;
        // height: 37px;
        // border-radius: 4px;
        font-size: 32px;
      }
    }
    .name-role {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .role {
        font-size: 12px;
        text-transform: capitalize;
      }
    }
  }
  .card-details {
    margin-top: 20px;
    width: 100%;
    .grid {
      display: grid;
      grid-template-columns: 2fr 3fr;
      row-gap: 20px;
      column-gap: 20px;
      .grid-item {
        align-self: stretch;
        .head {
          font-size: 12px;
          color: #939393;
        }
        .content {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
}
