.users-list{
    list-style-type: none;
    padding-left: 0;
    li{
        padding:7px;
        background-color: #eee;
        border-bottom:1px solid #fff;
        cursor: pointer;
    }
    .active-item{
        background-color: var(--color-green-3);
        color:#fff;
    }
}

.btn-disabled{
    border:none;
    padding:5px;
    border-radius: 3px;
    margin-left:10px;
}
.add-btn{
    border:none;
    background-color:var(--color-green-3);
    color: #fff;
    padding:5px;
    border-radius: 3px;
    margin-left:10px;
}
.message{
    margin:16px 0;
    color: var(--color-green-3);
}


.assigned-users{
    list-style-type: none;
    padding-left: 0;
    text-transform: capitalize;
    li{
        background-color:#eee!important;
        padding:4px!important;
        border-bottom: 1px solid #fff!important;
        font-weight: 600;
        margin-bottom:0!important;
    }
}
.assign-users{
 padding-top:2px;  
 .css-abna4k-MuiList-root {
    width: 275px; 
}
.css-et1ao3-MuiTypography-root {
    // letter-spacing: 0.2px;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif !important
}
.css-83ijpv-MuiTypography-root {
    letter-spacing: 0.2px;
    font-size: 13px;
    font-family: 'Work Sans', sans-serif !important
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #212529;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #212529;
}
.css-355xbt-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-355xbt-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #212529;
}
.css-qyxyi3-MuiButtonBase-root-MuiListItem-root {
    padding-top: 1px;
    height: 15%;
    padding-bottom: 1px;
   
}
.css-ak74bx-MuiButtonBase-root-MuiButton-root {
    padding: 7px 12px;
    color:black;
    border: 1px solid black;
}
}
.assigned-user{
    display: flex;
    justify-content: right;
    padding-top: 17px;
}
