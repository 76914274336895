.log-history {
    .MuiTypography-root {
        font-family: "Work Sans";
    }
    .MuiTypography-body1 li div {
        font-size: 14px;
        font-weight: 300;
    }
    .header-topic{position: relative;}

    .lrnaid_col {
        min-width: 150px;
    }
    div.vertical-timeline::before {
        background: var(--color-green-3);
    }
    .history-list-lrn {
        width: 100%;
    }

    .flex-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 15px 10px;
        border-bottom: 1px solid #ddd;
        .comment-icon{
            cursor: pointer;
            color: #ddd;
        }
    }
    .tp-top-head {
        background-color: #fff;
        padding: 7px;
        cursor: pointer;
    }
    .status-list {
        list-style-type: none;
        padding-left: 0;
        background-color: #fff;
        width: 25%;
        position: absolute;
        z-index: 2;
        box-shadow: var(--box-shadow-4);
        li {
            padding: 4px 20px;
            cursor: pointer;
            border-bottom: 1px solid #eee;
        }
        .status-selected {
            background-color: #0178E6;
            color: #fff;
        }
    }
    .tp-edit {
        cursor: pointer;
    }
    .select-status {
        border: none;
        outline: none;
        font-weight: 300;
    }
    .sup-holder{
        display: flex;
        align-items: center;
        .sup-cname{
            width: 100%;
        }
        .arrow-back{
            cursor: pointer;
        }
    }
    .supplimentary_cname{
        text-align: center;
        padding: 16px;
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }
}
