.user-container{
   
    .role-select{
        width:100%;
        outline: none;
        border: 1px solid #ced4da;
        padding:8px;
        border-radius:3px;
    }
    .sup-holder{
        display: flex;
        align-items: center;
        .sup-cname{
            width: 100%;
        }
        .arrow-back{
            cursor: pointer;
        }
    }
    .supplimentary_cname{
        text-align: center;
        padding: 16px;
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }
    .user-form{
        box-shadow: var(--box-shadow-3);
        padding: 20px;
        background-color: #fff;
        .form-group{
            label{
                font-size: 13px;
                font-weight: 500;
            }
            .error-msg{
                color:red;
                font-size:12px;
            }
        }
        
        .btn-items{
            padding-top: 20px;
            display:flex;
            justify-content: flex-end;
            .btn-sign{
                background-color: #0178E6;
                outline: none;
                padding: 5px;
                color:#fff;
                min-width:100px;
            }
            .btn-sign:hover{
                color:#fff;
            }
            .btn-cancel{
                background-color: #ddd;
                margin-left: 10px;
            }
        }
    }
}
.nav-open{
    margin-left: 200px;
    @media (max-width: 768px) {
        margin-left: auto;
    }
   }
   .nav-close{
    margin-left: auto;
   }