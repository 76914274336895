.ce-admin-side-nav{
    height: calc(100vh - 60px);
    background-color:var(--color-white);
    width:100%;
    transition: 0.5s;
    position:fixed;
    width:280px;
    box-shadow: var(--box-shadow-3);
    z-index: 1001;
   .menu-items{
       padding-left:0;
       list-style-type: none;
       .active-menu{
           background-color:var(--color-green-3);
           color:#fff;
       }
       li{
           display: flex;
           align-items: center;
           justify-content: space-between;
           padding: 10px 0;
           border-bottom: 1px solid var(--color-rgb-gray-0);
           cursor: pointer;
           .icon-item{
               flex:1;
               font-size:20px;
               text-align: center;
           }
           .menu-item{
               flex:4;
               text-align: left;
           }
       }
   }
}



.side-nav-close{
    width:71px;
    @media (max-width:1024px) {
        display: none;
    }
    .menu-items{
        padding-left:0;
        list-style-type: none;
        .menu-item{
            display: none;
        }
    }
}

