$border-color: #ccc;
$background-color: #f9f9f9;

.tooltip-table-container {
  max-width: 300px;
  max-height: 200px;
  overflow: auto; // Enable scrolling
  border: 1px solid $border-color;
  background-color: #fff;
  z-index: 999;
  cursor: auto;

  .tooltip-table {
    width: 100%;
    height: 100%; // Full height of the container
    overflow: hidden;
    border-collapse: collapse;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        background: $background-color;
      }

      th,
      td {
        border: 1px solid $border-color;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: $background-color;
      }
    }
  }
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    border: 1px solid $border-color;
    padding: 10px;
    text-align: left;
  }

  th {
    position: sticky;
    top: 0;
    background: $background-color;
  }
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  button {
    padding: 5px 10px;
    cursor: pointer;
  }
}

.fixed-header {
  position: sticky;
  top: 0;
  background-color: $background-color;
  z-index: 1;
}

.table-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid $border-color;
}
.gm-style-iw-c{
  flex-direction: row-reverse!important ;
  justify-content: center!important ;
  align-items: center!important ;
}
.gm-ui-hover-effect span{
  width: 20px!important;
  height: 24px!important;
  margin: 5px 5px!important;
}
.gm-style-iw-d{
  margin-top: 15px!important;
}
/* Add this CSS to style the layout */
.map-holder {
  display: flex;
  // height: 100vh;
}

.left-pane {
  flex: 1;
  height: 600px;
  width: 50%;
  .marker-cluster-medium div{
    background-color:#0178E6!important;
    color: #fff;
  }
  .marker-cluster-large div{
    background-color:#FF0A3C!important;
    color: #fff;
  }
  .marker-cluster-small div{
    background-color:#000A50!important;
    color: #fff;
  }
}
.tooltip{
  display: flex;
  flex-direction: column;
}
.head-for-activeSession{
  display: flex;
  justify-content: space-between;
  margin: 12px;
  .header-timeline-main{
    margin-top: 0;
  }
    button{
      background-color: #0178E6;
      color: #fff;
      border-radius: 25px;
      border: #0178E6;
      width: 100px   
     }
}
.activeUsers{
  padding-left: 18px;
  margin-top: 48px;
  width:640px ;
  min-width: 300px;
}
.allUsers{
  width:640px ;
  min-width: 300px;
}
.right-pane {
  display: flex;
  flex: 1;
  height: 600px;
  // overflow-y: auto;
  // padding: 20px;
  margin-top: 20px;
  .MuiAccordionDetails-root{
    flex-direction: column!important;
  }
  .marker-cluster-medium div{
    background-color:#0178E6!important;
  }
  .marker-cluster-large div{
    background-color:#444B4D!important;
  }
}

.accordion {
  margin-top: 20px;
}
.accordion-holder .MuiAccordionDetails-root{
  flex-direction: column!important;
}
.switch-holder{
  display: flex;
    justify-content: space-between;
}
.country-code-holder{
  display: flex;
  align-items: center;
}