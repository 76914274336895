.card-wrapper{
    margin: 4px 1px;
    min-width: 100px;
    max-width: 250px;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom:9px ;
    .image-wrapper{
        // width: 100%;
        img{
            width: 100%;
            height: 175px;
          object-fit: cover;
        //   border-radius: 10px;
        }
    }
    .content-wrapper{
        color: #000;
        padding: 8px;
        // h3{
        //     margin: 8px 0;
        // }
        // .desc{
        //    font-weight: 500;
        //    margin: 0; 
           
        // }
        // .content{
        //     margin: 0; 
        // }

        h3{
            color: green;
            margin: 0px 0;
            font-size: 18px;
            font-weight: 600;
        }
        .desc{
            font-weight: 500; 
            margin: 0; 
            font-size: 13px;

        }
        .content{
            
            margin: 0; 
            font-size: 12px;

        }
        .preview{
      display: flex;
      justify-content: end;
    position: relative;
            z-index: 5;
        // padding: 8px;
        margin-top: 4px;
        button{
            // padding: 4px 8px;
            border-radius: 5px;
            border: 0px;
            outline: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            // box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.2);
            background-color: #0178E6;
            color: #fff;
            line-height: 1.5;
           
        
        }
        
    } 
    
}
}


.content-container{
   
    &.highlighted{
        background-color: #cce4fa;
        // &::after{
        //             width: 10px;
        //             position: absolute;
        //             height: 100%;
        //             content: "";
        //             background-color: inherit;
        //             z-index: 10;
        //             right: 0%;
        //             top: 0;
        //             transform: translateX(100%);
        //         }
    }
    .content-wrapper{
        color: #000;
        padding: 10px 25px 10px 25px;
        position: relative;
        .arrow-icon{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            color: #0178E6;
        }
        // border-radius: 5px;
        // padding: 8px;
        // h3{
        //     color: green;
        //     margin: 0px 0;
        //     font-size: 18px;
        //     font-weight: 600;
        // }
        // .desc{
        //     font-weight: 500; 
        //     margin: 0; 
        //     font-size: 13px;

        // }
        // .content{
        //     line-height: 16px;
        //     margin: 0; 
        //     font-size: 12px;

        // }
        .user-detail{
            .user-image{
                width: 40px;
                font-size: 30px;
            }
            .user-name-email{
                h3{
                    font-size: 14px;
                    font-weight: 600;

                }
                .email{
                    font-size: 12px;
                    color: #b2b2b2;
                }
            }
        }
        .user-comment{
            .comment-icon{
                flex: 0 0 auto;
                width: 40px;
                font-size: 14px;
                color: #b2b2b2;
                
            }
            .comment{
                font-size: 12px;
                line-height: 14px;
            }
        }
    
    .preview{
        display: flex;
        justify-content: end;
      position: relative;
              z-index: 5;
          // padding: 8px;
          margin-top: 4px;
          button{
            //   padding: 4px 8px;
              padding: 2px 16px;
              border-radius: 5px;
              border: 0px;
              outline: none;
              cursor: pointer;
              font-size: 14px;
              // box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.2);
              background-color: white;
              font-weight: 600;
              color: #0178E6;
              line-height: 1.5;
              &:hover{
                  font-weight: 500;
                background: #0178E6;
                color: #fff;
            }
          
          }
          
      }  
    
}
}