body {
  margin: 0;
  padding:0;
  font-family: 'Work Sans', sans-serif !important
}

.mT20{
  margin-top:20px!important;
}
.pL0{
  padding-left: 0!important;
}

.title-uppercase{
  text-transform: uppercase;
}

.label-text{
  font-size: 12px;
}
.text-bold{
  font-weight: 600;
}
.react-toast-notifications__container{
  z-index: 1100 !important;
}