.role-stats-accordion {
  margin: 0 auto;
  min-width: 260px;
  width: 100%;
  max-width: 1300px;
  .card-grid {
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 350px));
    position: relative;
    z-index: 100;
    column-gap: 12px;
    row-gap: 20px;
    .card-header {
      background-color: #eee;
    }
    .card-content-text {
      padding: 4px 16px 16px;
      p {
        margin-bottom: 4px;
      }
    }
  }
  .color-ce-green {
    color: #0178E6;
    width: 100%;
    height: 100%;
  }
  .accordion-title {
    font-weight: 700;
    font-size: 20px;
  }
  .css-qcnhuq-MuiPaper-root-MuiAccordion-root {
    &:before {
      display: none;
    }
  }

  .MuiAccordionDetails-root {
    padding: 8px 64px 0;
    margin-bottom: 30px;
  }
  // .css-pu1dya-MuiPaper-root-MuiAccordion-root {
  //   box-shadow: none !important;
  // }
  .MuiAccordionDetails-root {
    p.no-data-role-p {
      //
    }
  }
}
