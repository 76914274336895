// .App{
//     padding: 1.5rem 0;
// }
.client-dropdown{
    margin-bottom: 10px;
}
.timeline-breadcrumbs{
    color:#0178E6;
    width: 100%;
    font-weight: 600;
    background-color: #e6f2fd;
    border-radius: 6px;
    // min-height: 30px;
    padding: 10px 16px ;
    
}
.header-timeline-main{
    color: #0178E6;
    font-size: 20px;
    font-weight: 600;
    text-align: start;
    margin-top: 30px;
    &.header-align{
        padding-left: 40px;
        margin-bottom: 0;
    }
}
.tag-box{
    background-color: white;
    padding: 40px 40px;
    // width: 45%;
    // margin-top: 50px;
    // flex: 0 0 45%;
    .client-list{
        width: 100%;
        margin-bottom: 12px;
        .select-wrapper{
            width: 50%;
            max-width: 450px;
        }
    }
}
.timeline-page-wrapper{
    max-width: 1400px;
}
.header-bggrey{
    background-color: #efefef;
    font-size: 18px;
    font-weight: 600px;
    padding: 16px 20px; 
}
.timeline-container-changed{
    background-color: transparent;
    margin-top: 20px;
    gap: 32px;
    padding: 0px 0px 20px;
    border-radius: 3px;
    width: 100%;
    display: flex ;
    // flex-wrap: wrap;
    gap:2rem;
    overflow-x: scroll;

    .timeline-header{
        background-color: #efefef;
        font-size: 18px;
        font-weight: 600;
        padding: 16px 20px;
    }
    
}
.timeline-container-wrapper{
    
    border-radius: 10px;
    border: 1px solid #b5b5b5;
    width: 50%;
    overflow-x: hidden;
    // max-width: 592px;
    flex: 0 1 50%;
}
.timeline-container{
    
    overflow-y: scroll;
    overflow-x: hidden;
    // border-radius: 9px;
    width: 100%;
    height: 75vh;
    // position: relative;
    // margin-left: 15px;
    // border: 1px solid #13984a9c;
    padding: 0px 0px 0px 4px;
    // padding-right: 0px !important;
    position: relative;
    .no-data-wrapper{
        position: absolute;
        top:50%;
        transform: translateY(-60%);
        display: flex;
        flex-direction: column;
        align-items: center;
        // transform: translateX(10%);
        .no-client-data{
            color: #0178E6;
            font-weight: 500;
            padding: 1rem;
            font-size: larger;
            text-align: center;
            // vertical-align: ceil($number: 50);
        }
    }
    
}

.timeline-vertical-circle > div:not(.using-icons).active{
/* border: 5px solid #00471e; */
border: none;
/* background-image: url("tick.svg");
background-position: center;
background-repeat: no-repeat; */
}
.title-chrono{
    background-color: transparent !important;
    font-size: 11px !important;
    // font-size: 13px !important;
    text-align: center !important;
    // z-index: 1000000 !important;
    position: relative !important;
    color: #0178E6 !important;
    font-weight: 400 !important;
    // overflow: scroll !important;
}
.timeline-main-wrapper.timeline-main-wrapper{
    padding: 0;
}
.timeline-main-wrapper > div{
    
    padding-top: 0;
    padding-bottom: 0;
}
.timeline-main-wrapper > div > .vertical-item-row:first-of-type{
    margin-top: 0;
}
.timeline-main-wrapper> div > .vertical-item-row:last-of-type{
    
    margin-bottom: 0;
}
.timeline-main-wrapper > div > .vertical-item-row{
    margin: 0;
    
        >div:last-of-type{
            width: 20px;
            position: relative;
            z-index: 2;
            button{
                padding: 0;
            }
        }
    
}
.timeline-main-wrapper> div > .vertical-item-row > .card-content-wrapper{
    width: calc(90% - 80px);
    position: relative;
    flex-grow: 1;
    left: -10px;
}
.timeline-main-wrapper> div > .vertical-item-row > .card-content-wrapper > section{
    /* pointer-events: none; */
width: 100%;
}
.mycard-chrono{
    filter: unset !important;
    border-bottom: 1px solid lightgray !important;
    border-radius: 0px !important ;
    width: 100% !important;
    z-index: 10;
    background: transparent;
    >span{
        display: none;
    }
    
   
    
}
.dropdown-icon-user-courses{
    color: #0178E6;
    background-color: #f5f5f5;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    // padding: 20px;
    // height: 100%;
}

// .mycard-chrono:last-of-type{
//     border: 0px !important;
   
// }
.timeline-main-wrapper.timeline-main-wrapper > div > div{
    margin: 0px 0px ;
    justify-content: flex-start;
}

.mycard-chrono> .card-text {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    padding: 0;
    margin: 0;
    width: 100%;
    &::after{
        display: none;
    }
    
}
.timeline-main-wrapper > div {
    padding: 0;
}
.timeline-main-wrapper > div > div>div:first-of-type{
    width: 80px;
}
.content-container{
   
    // border: 1px solid black;
    // padding: 6px 0px 6px;
//    position: relative;
    width: 100%;
    

    /* pointer-events: all; */
}
/* @keyframes moveRtoL {
    0%{
        transform: translateX(50%);
    }
    100%{
        transform: translateX(0);
    }
    
} */

.content-container{
    /* pointer-events: all; */
    /* position: relative;
    animation: moveRtoL 2s ease-in; */

}
.timeline-main-wrapper > div > .vertical-item-row:first-of-type .mycard-chrono{
    // margin-top: 4px;
}

.timeline-main-wrapper > div > .vertical-item-row:last-of-type .mycard-chrono{
    // margin-bottom: 4px;
    border-bottom: 0px !important;
}
.timeline-vertical-circle{
    
}
.timeline-vertical-circle::after{

}

.title-header{
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .loader-wrapper{
    height: 50px;
    &.courses-loader-wrapper{
        height: 30px;
    }

  }
.loader-cnt{
    /* position: absolute; */
    justify-content: flex-start;
    padding-top: 5px;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    &.small-loader{
        gap: 15px;
        font-size: 14px;

        i{
            font-size: 20px;
            
        }
    }
    /* align-items: center; */
    /* bottom: 10%;
    left: 10px; */
}
.loader-cnt > i {
    color: #0178E6;
    transform: rotate(360deg);
    font-size: 24px;
    transform-origin: center;
    animation: spin 1s linear infinite;
}

/* ********** */
/* svroll bar */
/* ********** */

/* Change scrollbar color */
::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
    /* border-radius: 9px; */
    overflow: hidden;
  }
  
  ::-webkit-scrollbar-thumb {
    /* background-color: #3498db; Set the color of the thumb (scrollbar handle) */
    background-color: #0178E6; /* Set the color of the thumb (scrollbar handle) */
    border-radius: 11px;
}

::-webkit-scrollbar-track {
      border-radius:11px;
    background-color: transparent; /* Set the color of the track (the area behind the thumb) */
  }
  
  /* Optional: Change scrollbar corner (where horizontal and vertical scrollbars meet) color */
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .using-icon{
    background-color: #0178E6 !important;
  }
  
  .show-details-container{
    p{
        strong{
            text-align: end;
            display: inline-block;
            width: 50px;
        }
    }
  }
  .course-selection{
    
    .dropdown-icon-user-courses{
        color: #0178E6;
        position: absolute; 
        background-color: transparent;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .courses-lrns-wrapper{
        height: 75vh;
    }
    .crs-lrns-select{
        .MuiOutlinedInput-root{
            border-radius: 0px !important;
            .MuiOutlinedInput-notchedOutline{
            border-radius: 0px;
            border: none;
            outline: none;
        }
        .MuiOutlinedInput-input{
              border-radius: 0px;
              background-color: #f5f5f5;
              font-size: 14px;
              color: #0178E6;
              padding: 20px;
              font-weight: 600;
              
          }
          &:first-of-type{
            border-bottom: 1px solid #e5e5e5;
            
      }
      }
    }
   .courses-lrns{
    overflow-y: auto;
    overflow-x: hidden;
    height: 60vh;
   }
   
  }
  .learning-element-data{
    gap: 2rem;
  }
.user-selection, .course-selection{
    position: relative;
    border-radius: 10px;
    border: 1px solid lightgrey;
    overflow: hidden;
    .user-box{
        width: 100%;
        padding: 8px 8px;
        cursor: pointer;
        border-bottom: 1px solid lightgrey;
        &:hover{
            background-color: #f7f7f7;
        }
        &:last-of-type{
            border-bottom: none;
        }
        &.selected-user{
            background-color: #cce4fa;
        }
    }
}
.user-selection{
    .users{
    overflow-y: auto;
    overflow-x: hidden;
    height: 75vh;
    }
}
    
    // &::after{
    //     content: "User List";
    //     position: absolute;
    //    top:0;
    //    left: 10px;
    //    background-color: #ffff;
    //    padding: 4px;
    //    transform: translateY(-50%);
    //     z-index: 10;

    // }
//     .MuiSelect-select{
//     }
// .dropdown-icon-user-courses{
//     color: #0178E6;
//     position: absolute; 
//     background-color: transparent;
//     right: -10px;
//     top: 50%;
//     transform: translateY(-50%);
// }

  .course-selection-data-wrapper{
    border-radius: 10px;
   
    width: 50%;
    flex: 0 1 50%;
      
    //   margin-top: 50px;
      overflow-x: hidden;
    border: 1px solid #b5b5b5;
    .header{
        background-color: #efefef;
        font-size: 18px;
        font-weight: 600px;
        padding: 16px 20px;
    }
    .course-selector{
        // max-height: 75vh;
        overflow: hidden;
    //   background-color: rgb(248,248,248);
      // div{
      //     margin-left: 4px;
      //     margin-top: 4px;
      // }
      .MuiOutlinedInput-root{
            border-radius: 0px !important;
            .MuiOutlinedInput-notchedOutline{
            border-radius: 0px;
            border: none;
            outline: none;
          }
          .MuiOutlinedInput-input{
            background-color: #f5f5f5;
            font-size: 14px;
            color: #0178E6;
            padding: 20px;
            font-weight: 600;
          }
      }
      
      .courses-data-wrap{
            min-height: 200px;
          max-height: 650px;
        height: 75vh;
          overflow-y: scroll;
          width: 100%;
          // position: relative;
          .accordion-fetch{
              // position: absolute;
              display: block;
            width: 100px;
            margin-left: auto;
            margin-top: 5px;
            background-color:transparent ;
            border: none;
            color: #0178E6;
            font-weight: 500;
            &.hidden{
              display: none;
            }
              
          }
          .courses-data{
              font-size: 13px;
             
              padding: 8px 40px;
             
              border-bottom: 1px solid lightgray;
              .user-timestamp{
                  font-size: 12px;
                  color: #b2b2b2;
    
              }
              .user-comment{
                  .comment-icon{
                      font-size: 14px;
                      color: #b2b2b2;
                  }
                  .comment{
                      font-size: 12px;
                      font-weight: 500;
                  }
              }
          }
    
      }
    }

  }
  .MuiMenu-list{
    max-width: 590px;
  }
  .courses-lrns{
    .courses-data{
        font-size: 13px;
       
        padding: 8px 40px;
       
        border-bottom: 1px solid lightgray;
        .user-timestamp{
            font-size: 12px;
            color: #b2b2b2;

        }
        .user-comment{
            .comment-icon{
                font-size: 14px;
                color: #b2b2b2;
            }
            .comment{
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
  }

  .empty-lrn-box{
    img{
        padding: 16px 20px;
        max-width: 350px;
        object-fit: contain;
        width: 100%;
        min-width: 200px;
    }
    p{
        color: #0178E6;
    }
  }
// .course-accordion-wrapper{
//     border-radius: 10px;
   
//     width: 50%;
//     flex: 0 1 50%;
      
//     //   margin-top: 50px;
//       overflow-x: hidden;
//     border: 1px solid #b5b5b5;
//     .header{
//         background-color: #efefef;
//         font-size: 18px;
//         font-weight: 600px;
//         padding: 16px 20px;
//     }
//     .course-accordion{
//         // max-height: 75vh;
//         // overflow-y: auto;
//     //   background-color: rgb(248,248,248);
//       // div{
//       //     margin-left: 4px;
//       //     margin-top: 4px;
//       // }
//       .MuiAccordionSummary-root{
//           background-color: #f5f5f5;
//           font-size: 14px;
//           font-weight: 600;
//       }
//       .accordion-data-wrap{
//         //   max-height: 50vh;
//           overflow-y: scroll;
//           width: 100%;
//           // position: relative;
//           .accordion-fetch{
//               // position: absolute;
//               display: block;
//             width: 100px;
//             margin-left: auto;
//             margin-top: 5px;
//             background-color:transparent ;
//             border: none;
//             color: #0178E6;
//             font-weight: 500;
//             &.hidden{
//               display: none;
//             }
              
//           }
//           .accordion-data{
//               font-size: 13px;
              
//               padding: 10px 0;
             
//               border-bottom: 1px solid lightgray;
              
//               // p{
//               //     &:first-of-type{
//               //         margin-top: 2px;
//               //         font-size: 12px;
//               //         flex-shrink: 0;
//               //         width: 150px;
//               //     }
//               // }
//               // p{
//               //     margin: 0;
//               // }
//               .user-timestamp{
//                   font-size: 12px;
//                   color: #b2b2b2;
    
//               }
//               .user-comment{
//                   .comment-icon{
//                       font-size: 14px;
//                       color: #b2b2b2;
//                   }
//                   .comment{
//                       font-size: 12px;
//                       font-weight: 500;
//                   }
//               }
//           }
    
//       }
//     }
// }