.admin-tag-continer {
    .tag-header {
        display: flex;
        flex-direction: row;
        padding: 16px 0;
        .heading-text {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            width: 100%;
        }
    }
    .tag-box {
        width: 100%;
        padding: 16px;
        background-color: #fff;
        .add-box {
            padding: 16px 0;
            display: flex;
            justify-content: space-between;
            .add-box-inner {
                width: 100%;
            }
            .save-btn {
                cursor: pointer;
                color: #b9b8b8;
                background: #dbdbdb;
                font-weight: bold;
                &:hover {
                    background-color: var(--bs-green);
                }
            }
            .active-save{
                background: #000;
                color: var(--bs-body-bg);
            }
            .add-text {
                width: 38%;
            }
            .icon {
                border-radius: 50%;
                background: #000;
                color: var(--bs-body-bg);
                font-size: 30px;
                margin: 0 10px;
                cursor: pointer;
                &:hover {
                    background-color: var(--bs-green);
                }
            }
        }
        .list-items {
            max-height: 75vh;
            overflow-y: scroll;
            padding: 16px;
            .active-tag {
                background-color: #e9ecef;
                font-weight: bold;
                p {
                    margin: 0;
                    color: rgb(201, 24, 24);
                }
                .delete-btn{
                    color: rgb(201, 24, 24) !important;
                }
                .btn {
                    color: #000;
                    font-weight: bold;
                }
            }
            .list-box {
                border: 1px solid #dee2e6;
                &:hover {
                    box-shadow: var(--box-shadow-2);
                    background-color: var(--bs-gray-100);
                }
                .list-item {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 16px;
                    align-items: center;
                    .lable {
                        margin: 0;
                    }
                    .actions {
                        display: flex;
                        min-width: 10%;
                        justify-content: space-between;
                        .icon {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

