.update-continer{
    background-color: #fff;
    border-radius: 1.3px;
    padding: 16px;
    .updates-list{
        height: 85vh;
        overflow-y: scroll;
        list-style-type: none;
        margin: 0;
        padding: 0;
        @media (max-width:590px) {
            max-height: 300px;
        }
        .active-update{
            background-color: var(--color-green-3) !important;
            color: #fff;
            font-weight: 500;
        }
        .updates{
            border-bottom: 1px solid #ddd;
            padding: 20px 10px 10px 20px;
            cursor: pointer;
            &:last-child{
                border-bottom: none;
            };
            &:hover{
                background-color: #ddd
            };
        }

    }
    .slected-cntent{
        padding: 16px 10px ;
        overflow-y: auto;
        height: 85vh;
        .list-heading{
            font-size: 20px;
            font-weight: bolder;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding-bottom: 16px;
            .heading{
                width: 80%;
            }
            .edit-action{
            font-size: 18px;
            color: var(--bs-gray-600);
            padding: 0 16px;
            cursor: pointer;
            i{padding: 0 3px;}
            &:hover{
                color: var(--color-green-3);
                i{
                    font-weight: bold;
                }
            }
            }
        }
    }
}