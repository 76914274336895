.user-accordion-items{
    .color-ce-green{
        color:#13984B;
        width: 35px;
        height: 35px;
    }
    .flex-item{
        display: flex;
        align-items: center;
        justify-content:flex-start;
        width: 100%;
    }
    .ml10{
        margin-left: 10px;
    }
}

.btn-sign{
    background-color: #0178E6;
    outline: none;
    padding: 5px;
    color:#fff;
    min-width:100px;
}