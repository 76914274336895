
.richtxt-editor-wrapper-class{
    border:1px solid #d1d1d1;
    border-radius:4px,
}
.rdw-dropdown-selectedtext{
    color:#000
}

.rdw-dropdown-selectedtext:hover{
    color:#000
}
.rdw-editor-main{
    height: 300px !important;
}
