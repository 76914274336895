.analytics-container {
  .analytics-title {
    p {
      color: #0178E6;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 12px;
    }
  }
  .tag-box {
    padding: 28px 30px;
    min-width: 1000px;
    width: 100%;
    max-width: 1600px;
    // width: 80vw;

    border-radius: 8px;
    background-color: #fff;
    .company-table {
      p {
        text-align: center;
        padding: 0.5rem;
      }
      p:hover {
        cursor: pointer;
        background-color: #ddd;
      }
    }
    .search-bar-auto{
      position: relative;
      width: 508px;
      .MuiAutocomplete-endAdornment{
        display: none;
      }
      .search-icon-auto{
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        .fa.fa-search {
          font-size: 24px;
          color: #0178E6
        }
        .fa.fa-circle-o-notch{
          @keyframes loading{
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          font-size: 24px;
          color: #0178E6;
          animation: loading 1s ease-in-out infinite;

        }
      }
    }
    .company-search {
      display: flex;
      justify-content: start;

      .search {
        position: relative;
        z-index: 2;
        padding: 10px 16px;
        border: 1px solid #e2e2e2;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
        // box-shadow: 0px 10px 20px 5px rgba(171, 171, 171, 0.3);
        border-radius: 5px;
        width: 508px;
        display: flex;
        align-items: center;
        &::after {
          content: "Search Company";
          background-color: #fff;
          position: absolute;
          color: #939393;
          top: 0;
          left: 16px;
          transform: translateY(-60%);
        }
        input {
          flex: auto;
          // width: 100%;
          outline: none;
          border: none;
        }
        .fa.fa-search {
          font-size: 24px;
        }
        .dropdown {
          position: absolute;
          left: 0;
          padding: 10px 0 10px;
          top: 100%;
          width: 100%;
          max-height: 250px;
          min-height: 0px;
          border-radius: 5px;
          border: 1px solid #e2e2e2;
          // box-shadow: #e6f5ed;
          background-color: #fff;
          color: black;
          overflow-y: scroll;
          z-index: 1000;
          div.pointer {
            padding: 4px 16px;
          }
          div.pointer:hover {
            background-color: #e9ecef;
          }
          .pointer {
            cursor: pointer;
            color: black;
          }
        }
      }
    }
    .company-details {
      margin: 0 auto;
      min-width: 260px;
      width: 100%;
      max-width: 1300px;
      margin-bottom: 16px;
      padding: 0px 16px;
      .company-info {
        width: 65%;
        p {
          margin-bottom: 5px;
        }

        .company-info-item {
          color: #939393;
          font-size: 14px;
        }
        .company-data {
          font-size: 16px;
          font-weight: 600;
        }
        .company-data-seats {
          padding-bottom: 8px;
          span {
            font-weight: 600;
          }
        }
      }
      .company-delegation-details {
        width: 350px;
        .delegation-box-title {
          font-size: 14px;
          margin-bottom: 0 !important;
          color: #939393;
        }
        .delegation-tabs {
          border-bottom: 1px solid #d3d3d3;
          .tab {
            cursor: pointer;
            display: inline-block;
            width: 50%;
            position: relative;
            p {
              margin-bottom: 0;
              // font-size: 16px;
              pointer-events: none;
            }
            &.selected {
              p {
                font-weight: 600;
              }
              padding: 4px 0;

              &:after {
                content: "";
                height: 4px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                transform: translateY(50%);
                z-index: 2;
                background-color: #0178E6;
              }
            }
          }
        }
        .delegation-card {
          .card-container {
            border: none;
          }
        }
      }
    }
    .role-cards-container {
      margin: 0 auto;
      // height: min-content;
      min-width: 260px;
      width: 100%;
      max-width: 1000px;
    }
    .reset-program-writer{
      button{
        background-color: #0178E6 !important;
        &:hover{
          background-color: #0178E6 !important;
        }
        &.Mui-disabled{
          
          color: #fff;
        }
      }
      .fa.fa-circle-o-notch{
        @keyframes loading{
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        font-size: 20px;
        // color: #13984b;
        animation: loading 1s ease-in-out infinite;

      }
    }
  }
}
