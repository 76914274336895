.users-list-holder{
    position:relative;
 .users-list{
     list-style-type: none;
     padding-left: 0;
     box-shadow: var(--box-shadow-3);
     @media (max-width:768px) {
        overflow: scroll;
        background-color: #ddd;
        box-shadow: var(--box-shadow-3);
        .users-list{
         box-shadow: none;
        }
        li{
         width: fit-content;
        }
        .name-item{
            min-width: 100px !important;
            position: sticky;
            left: 0;
            min-height: 25px;
            background-color: #fff;
            z-index: 2;
            overflow-x: auto;
        }
        .header-item .name-item{
            background-color:#ddd ;
        }
     }
     .header-item{
         background-color: #ddd;
         padding:10px 10px;
         font-weight: 600;
     }
     .name-item{
         min-width:200px;
         text-align:left;
         padding-left: 20px;
     }
     div:not(.name-item){
         min-width:120px;
         text-align: center;
     }
     li{
         padding:4px;
         background-color: #fff;
         border-bottom: 1px solid #ddd;
         cursor: pointer;
         font-size: var(--list-font-size);
       
         .flex-item{
             display: flex;
             align-items: center;
             justify-content:space-between;
         }
     }
    
 }
 
} 
.users-list-holder .users-list div:not(.name-item){
    position: relative;
 }
.options-area{
    position: absolute;
    right: 70px;
    background-color: #ddd;
    box-shadow: var(--box-shadow-2);
    margin-top: -25px;
    text-align: center;
    font-size: 14px;
    ul{
        list-style-type: none;
        padding-left: 0;
        z-index: 999;
        li{
            background-color:var(--color-white)!important;
            color:#0000008a;
            padding:2px 10px!important;
            border-bottom: 1px solid #eee!important;
            font-weight: 400 ;
            margin-bottom:0!important;
        }
    }
}