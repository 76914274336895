.btn-items{
    padding-top: 20px;
    display:flex;
    justify-content: flex-end;
    .btn{
        padding: 3px!important;
    }
    .btn-sign{
        background-color: #0178E6;
        outline: none;
        color:#fff;
        min-width:100px;
        
    }
    .btn-sign:hover{
        color:#fff;
    }
    .btn-cancel{
        background-color: #ddd;
        margin-left: 10px;
    }
}