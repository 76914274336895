

.side-sheet-container{
    padding:25px;
    @media (max-width:600px) {
        width: 350px !important;
    }
    .flex-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:10px;
    }
    .sheet-title{
        text-transform: uppercase;
    }
    .fa-close{
        font-size: 30px !important;
        cursor: pointer;
    }
    
    .btn-actions{
        position: absolute;
        bottom: 0;
        min-height: 100px;
        box-shadow:var(--box-shadow-3);
        background: #eee;
        width: 100%;
        left: 0;
        padding: 10px;
    }
}