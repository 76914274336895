.tooltip-box {
  display: flex;
  gap: 16px;
  // flex-direction: column;
  align-items: center;
}

.box-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* box */
}

  .remove-graph-icon{
    i{
      cursor: pointer;
    }
    padding: 0;
    border: none;
    margin-left: 5px;
    
    color: black;
    font-size: 16px;
  }

.statistics-wrapper {
  max-width: 1400px;
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll{
    padding: 10px 0;
    .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
      border-color: #333;
    }
  }
  .react-datepicker-popper{
    z-index: 10;
  }
  .statistics-tag-container {
    .statistics-no-data{
      display: flex;
      flex-direction: column-reverse;

      align-items: center;
    }
    .tag-header {
      display: flex;
      flex-direction: row;
      padding: 16px 0;
      &.header-align{
        padding: 16px 45px 0;

      }
      .heading-text {   
        // text-align: center;
        color: #0178E6;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        width: 100%;
      }
    }
    .tag-box {
      width: 100%;
      padding: 35px 45px;
      background-color: #fff;
      .stats-client-date-range{
        display: grid;
        // grid-template-rows: repeat(2,1fr);
        grid-template-columns: repeat(2,1fr);
        width: 400px;
        row-gap: 16px;
        .client{
          grid-column-start: 1;
          grid-column-end: 3;
          select{
           
            width:360px;
          
          }
        }
      }
      .graph-selection{
        .graph-selection-grid{
          display: grid;
          grid-template-rows: repeat(2,1fr);
          grid-template-columns: repeat(2,1fr);
          row-gap: 16px;
          width: 520px;
          .stats-selection{
            width: 250px;
            .select-wrapper:first-of-type{
              max-width: calc(100% - 17.57px) ;
            }
            i{
              color: red;
            }  
            div{
              width: 100%;
              // select{
              //   width: 100%;
              // }
            }
          }
        }
      }
      
      .user-interaction-graph{
        border: 1px solid #b5b5b5;
        border-radius: 11px;
        overflow: hidden;
        margin-top: 24px;
        height: auto;
        transition: height 500ms ease-in-out;
        .graph-header{
          position: relative;
          z-index: 5;
          h4{
            background-color: #efefef;
            font-size: 18px;
            font-weight: 600;
            padding: 15px 20px;
            margin: 0;
            border-bottom: 1px solid #b5b5b5;
          }
          .collapse-switch{
            position: absolute;
            right: 20px;
            top: 50%;
            color: #13984B;
            cursor: pointer;
            padding: 4px 2px;
            font-weight: 500;
            font-size: 14px;
            transform: translateY(-50%);
          }
        }
        .user-interaction-collapsible-wrapper{
          position: relative;
          z-index: 3;
          transition: top 500ms ease-in-out;
        }
        .graph-stats{
          display: flex;
          justify-content: center;
          border-top: 1px solid #b5b5b5;
          .stats{
            padding: 16px 32px;
            text-align: center;
            border-left: 1px solid #b5b5b5;
            &:last-of-type{
              border-right: 1px solid #b5b5b5;
            }
            p{
              margin: 0;
            }
            .title{
              font-size: 14px;
              // font-weight: 500;
            }
            .stat-data{
              font-size: 20px;
              font-weight: 600;
            }
            .interval{
              font-size: 14px;
   
            }
          }
   
        }
      }
    }
  }
  
  .stats-selection{
    width: 180px;
    // min-width: 200px;
    display: flex;
    flex-direction: column;

    flex-shrink: 0;
    // justify-content: flex-start;
    align-items: baseline;
    select{
      border-radius: 5px;
      border-color: #b9b9b9;

    }
    
    .long-label{
      // width: 110px;
    }
    label{

    }
    .react-datepicker__input-container .react-datepicker__calendar-icon{
      padding: 6px 8px;
      top:50%;
      right: 0;
      transform:translateY(-50%)
    }
    &.date-from{
      
      .react-datepicker__view-calendar-icon{
        &::after{
          position: absolute  ;
          content: "From";
          color: #939393;
          left: 8px;
          top:0;
          padding: 0px 2px;
          font-size: 12px;
          transform: translateY(-50%);  
          background-color: white;
        }
      }
    }
    &.date-to{

      .react-datepicker__view-calendar-icon{
        &::after{
          position: absolute  ;
          content: "To";
          color: #939393;
          left: 8px;
          top:0;
          padding: 0px 2px;
          font-size: 12px;
          transform: translateY(-50%);  
          background-color: white;
        }
      }
    }
    .react-datepicker__view-calendar-icon input{
      width: 160px;
      padding: 8px 15px 8px 30px;
      
      // background-color: #e6f5ed;
      border-radius: 5px;
      border: 1px solid #b9b9b9;
      
    }
    input.date-range-heatmap{
      min-width: 160px;
    max-width: 160px;
    padding: 2px 15px;
    background-color: #e6f5ed;
    outline: none;
    border: 1px solid rgb(118, 118, 118);
    }
  }
  label {
    margin-left: 12px;
    margin-bottom: 2px;
    flex-shrink: 0;
    width: 110px;
    text-align: start;
    // margin-bottom: 100px;
  }
  @media (max-width: 1000px) {
    margin-left: 2px;
    // margin-bottom: 100px;
  }
}

.statistics-wrapper {
  .chart-container {
    // margin-top: 120px;
&.calender-heatmap-height{
      height:350px;
    }
    &.cartesian-heatmap-height{
      height:460px;
    }

    height: 520px;
    // overflow-y: hidden;
    // box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.127);
    // border: 1px solid #ccc;
    // border-radius: 20px;
    // @media (max-width: 1000px) {
    //   overflow-x: scroll;
    //   overflow-y: hidden;
    //   height: 720px;
    // }
    @media (max-width: 1400px) {
      overflow-x: scroll;
      overflow-y: hidden;
      height: 520px;
      box-shadow: inset -5px 0 9px -10px rgba(0, 0, 0, 0.4);
    }
  }
}
// .user-select {
//   #month-select {
//     height: 30px;
//   }
//   #year-select {300px
//     height: 30px;
//   }
//   #week-select {
//     height: 30px;
//   }
//   #date-select {
//     height: 30px;
//   }
//   font-size: 16px;
//   .ipad-view {
//     @media (min-width: 1000px) {
//       display: none;
//     }
//   }
// }

select {
  // max-width: 140px;
  // min-width: 140px;
  padding: 4px 6px;
  // background-color: #e6f5ed;
  options {
    padding: 100px 20px;
    margin-left: 10px;
  }
}
// .company-search {
//   display: flex;
//   justify-content: center;
//   label {
//     text-align: right;
//     padding-right: 8px;
//   }
//   .search {
//     position: relative;
//     input {
//       padding-left: 0.5rem;
//     }
//     .dropdown {
//       position: absolute;
//       left: 0;
//       top: 100%;
//       width: 100%;
//       height: 250px;
//       border-radius: 5px;
//       border: 1px solid black;
//       box-shadow: #e6f5ed;
//       background-color: #fff;
//       color: aquamarine;
//       overflow-y: scroll;
//       z-index: 1;
//       .pointer {
//         cursor: pointer;
//       }
//     }
//   }
// }
.user-select {
  label {
    min-width: 120px;
    max-width: 120px;
    text-align: right;
    padding-right: 8px;
  }
}
// .custom-col-gap {
//   margin-right: -50px;
//   margin-left: -50px;
// }
.custom-col-gap > [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}
.list-items {
  max-height: 81vh;
  overflow: hidden;
  // overflow-x: scroll;
  padding: 16px;
}
.heatmap-side-data-name-date{
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}
.heatmap-side-data-stmt{
  margin: 0;
  padding: 4px 0;
  font-size: 13px;
}

.sidesheet-cartesian-date-map{
  .sidesheetinfo-charts-link{
    text-decoration: none;
    color: #13984B;
    padding: 4px;
    font-size: 14px;
    font-weight: 500;
    &:hover, &:visited, &:link, &:active{
      color: #13984B;
    } 
    &:hover{
      text-decoration: underline;
    }
  }
  .MuiAccordionSummary-root{
    padding-left: 10px !important;
    .MuiAccordionSummary-content{
      margin-top: 0;
      margin-bottom: 0;
    }

  }
  .MuiAccordionDetails-root{
    &.card-grid{
      padding-left: 10px !important;
    }

  }
  .accordion-summary-container{
    width: 100%;
    .user-icon-date{

      .image{
        font-size: 30px;
        // i{
        //   width: 25px;
        //   height: 25px;
        // }
      }
      .user-date{
        .user-name{
          font-weight: 600;
        }
        
       
      } 
    }

  }
  .heatmap-side-data-name-date{
    font-weight: 500;
    color: #a3a3a3;
  }
  .blank-date{
    width: 30px;
    flex-shrink: 0;
  }
  .date-comment{
    
  }
  .icon-comment{
    .icon{
      width: 30px;
      flex-shrink: 0;
    }
    .comment{
      font-size: 16px;
    }
  }
}

///////////////////////////////////
//////////////////////////////////

.statistic-info-grid-container{
// previous layout
  // .statistic-info-grid{
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   row-gap: 24px;
  //   column-gap: 22px;    
  //   align-items: stretch;

  //   .grid-item{
  //     min-width: 300px;
  //     // max-width: 600px;
  //     width: 100%;

  //   }
  // }

  // Current layout
  .statistic-info-grid{
    display: flex;
    gap: 22px;
    
    .grid-col{
      width: 50%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .grid-item{
      min-width: 300px;
      width: 100%;

    }
    @media (max-width: 991px) {
      flex-direction: column;
      .grid-col{
        width: 100%;
      }
    }
  }
}



/////////////////////////////////////
/// /////////////////////////////////
/// /////////////////////////////////
 
.graph-container-common{
  height: 100%;
  border-radius: 10px;
  border: 1px solid #B5B5B5;
  overflow: hidden;

  &.radar-container{
    overflow: visible;
    .graph-wrapper{
      overflow: visible;
      &.without-growth{
        height: 625px;
        padding-bottom: 0;
      }
    }
  }
  .header{
    padding: 18px 20px;
    font-size: 18px;
    font-weight: 600;
    background-color: #efefef;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .growth-box-details{
    text-align: center;
    padding: 28px 20px 0px;
    .growth-num{
      font-size: 50px;
      color: #13984B;
      font-weight: 700;
    }
.growth-percentage{
    font-size: 14px;
    .arrow-icon{
      color: #13984a80;
      display: inline-block;
    }
  .percentage{
    font-weight: 600;
    color: #13984B;
    
  }
  .days{
    font-weight: 500;
  }
}
  }
  .graph-wrapper{
    height: 425px;
    padding: 28px 0px 0px;
    overflow: hidden;
   
    &.without-growth{
     height: 680px;
     padding-bottom: 28px; 
    }
  }
  .short-info{
    color: #13984B;
    padding: 18px 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .list{
    .list-item{
      border-top: 1px solid #b5b5b5;
      padding: 12px 20px;
      gap: 16px;
      align-items: center;
      .user-icon{
        font-size: 32px;
      }
      .counts-box{
        font-size: 12px;
        padding: 4px 3px;
        box-shadow: 1px 2px 5px rgba(19, 152, 74, 0.2);
        // box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
        width: 54px;
        font-weight: 500;
        text-align: center;
        // border: 1px solid #13984B;
        border-radius: 5px;
      }
      .user-name-inter{
        .name{
          font-size: 14px;
          font-weight: 600;
        }
        .interaction{
          font-size: 12px;
          color: #878787;
        }
      }
    }
  }
  .view-more-box{
    color: #13984B;
    background-color: #dfffda;
    text-align: center;
    font-size: 14px;
    padding: 18px 20px;
    cursor: pointer;
    font-weight: 500;
  }
}

.sidesheet-viewmore-data-container{
  .list{
    .list-item{
      border-top: 1px solid #b5b5b5;
      padding: 12px 20px;
      gap: 16px;
      align-items: center;
      .user-icon{
        font-size: 32px;
      }
      .counts-box{
        font-size: 12px;
        padding: 4px 3px;
        box-shadow: 1px 2px 5px rgba(19, 152, 74, 0.2);
        width: 54px;
        font-weight: 500;
        text-align: center;
        // border: 1px solid #13984B;
        border-radius: 5px;
      }
      .user-name-inter{
        .name{
          font-size: 14px;
          font-weight: 600;
        }
        .interaction{
          font-size: 12px;
          color: #878787;
        }
      }
    }
  }
}