.common-tab-panel{
    min-height: 500px;
    .example{column-gap: 10px !important;}
    .css-19kzrtu{padding:0!important;}
    .Mui-selected{color: var(--color-green-3) !important;}
    .MuiTab-root {padding-bottom: 0!important;font-size: 18px; font-weight: bolder; min-width:110px; }
    .css-1aquho2-MuiTabs-indicator{background-color: var(--color-green-3) !important;column-gap: 10px !important;}
    .Mui-expanded{
        // height: 45px;
    }
    .css-smkl36-MuiCollapse-wrapper{
        border:2px dashed #ddd;
        min-height: 400px;
    }
}