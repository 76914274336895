.ce-content{
    height: calc(100vh - 60px);
    background-color:var(--color-white);
    .main{
        height: 100%;
        background-color:#eee;
        padding-top: 10px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .add-user-btn-holder{
        position: fixed;
        right: 30px;
        bottom: 30px;
        z-index: 3;
        text-align: right;
        // box-shadow: var(--box-shadow-3);
        .update-active{
          margin: 0;
          background-color: #fff;
          list-style: none;
          text-align: center;
          padding: 0;
          min-width: 130px;
          li{
            padding: 8px;
            border-bottom: 1px solid #ddd;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            &:hover{
                background-color: #ddd;
            }
          }
        }
        .fa-times-circle{
            font-size: 50px !important;
            cursor: pointer;
        }
        .fa-plus-circle{
            font-size: 50px;
            cursor: pointer;
           
        }
    }
}
