.select-wrapper {
    position: relative;
    display: inline-block; /* Ensures container wraps around select */
    select {
      width: 100%;
      margin-bottom: 0px;
      padding: 10px 8px;
      border-radius: 5px;
      border-color: #b9b9b9;
    }
    .client-text {
        position: absolute;
        width:max-content !important;
        color: #939393;
        top: 0;
        left: 8px;
        padding: 0px 3px;
        z-index: 2;
        display: block;
        background-color: #fff;
        font-size: 12px;
        transform: translateY(-50%);
      }
    
}