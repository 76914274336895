.course-assignment{
    .all-courses-list{
        list-style-type: none;
        padding-left: 0;
        box-shadow: var(--box-shadow-3);
        li{
            padding:var(--list-item-padding);
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            cursor: pointer;
            font-size: var(--list-font-size);
        }
        .selected{
            background-color:var(--color-green-4);
            color:#fff;
        }
    }
    .btn-items{
        display: flex;
        justify-content: flex-end;
        .btn-assign{
            background-color:var(--color-green-3);
            color:#fff;
        }
        .btn-cancel{
            margin-left: 6px;
            background-color: gray;
            color:#fff;
        }
    }
}