.sign-in-container{
    border:1px solid #ddd;
    width:800px;
    box-shadow: var(--box-shadow-4);
    @media (max-width:580px) {
        width:350px;
    }
}
.intro-section{
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -moz-box-pack: center;
    justify-content: center;
    // background-color: #42494b;
    height: 100%;
    padding: 20px;
    h5{
        color:#000;
        margin-top: 15px;
       
    }
    .copyright-heading{
        color: #000;
        font-size: 12px;
        font-weight: 600;
    }
    p{
        color:#fff
    }
    .divider{
    height: 5px;
    border-radius: 34px;
    width: 130px;
    background-color: #0178E6;
    margin:10px 0;
    }
}
.signup-form{
    padding: 30px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .form-group{
        label{
            font-size: 13px;
            font-weight: 500;
        }
        input{
            border-radius: 200px;
        }
        .error-msg{
            color:red;
            font-size:12px;
        }
    }
    
    .btn-sign{
        background-color: #0178E6;
        outline: none;
        padding: 5px;
        color:#fff;
        min-width:100px;
        border-radius: 200px;
    }
    .btn-sign:hover{
        color:#fff;
    }
}
.sign-label{
    label{

        color: #0178E6;
        font-weight: 600;
        cursor: pointer;
    }
    padding-left: 30px;
}
.error-msg{
    color: red;
}