@mixin for-mobile-small-devices {
    @media (max-width: 425px) {
       @content;
    }
  }
.ce-header{
    height:60px;
    background-color:var(--color-white);
    box-shadow:var(--box-shadow-3);
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    .brand-logo{
        width:230px;
        @include for-mobile-small-devices(){
            width:160px;
        }
    }
    .flex-item{
        display: flex;
        align-items: center;
        justify-content: right;
        margin-right: 15px;
        .user-role{
            @include for-mobile-small-devices(){
                display: none;
            }  
        }
    }
    .client-name{
        padding-top: 3px;
        margin-right: 15px;
        min-width: 140px;
    }

    .fa-power-off{
        vertical-align: middle;
        font-size:25px;
        margin-left: 10px;
        cursor: pointer;
        @include for-mobile-small-devices(){
            font-size: 20px;
        }
    }
    .fa-chevron-down{
        vertical-align: bottom;
        font-size: 16px;
        padding-left: 4px;
    }

    .user-info{
        text-transform: capitalize;
        font-weight: bold;
    }
    .container-fluid{
        flex-wrap: nowrap;
        padding: 0px 15px;
    }
}
.container-bars {
    display: inline-block;
    cursor: pointer;
    padding: 8px 25px 0 15px;
    @include for-mobile-small-devices(){
    margin-left:0px;
    }
  }
  .change{
    border-radius: 10%;
  }
  .bar1, .bar2, .bar3 {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 4px 0;
    transition: 0.4s;
    border-radius: 8px;
  }
  
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-8px, 4px);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-6px, -3px);
  }